import React from "react";
import { Link } from "react-router-dom";

function Verification() {
    return (
        <>
            <div className="body d-flex p-0 p-xl-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="d-flex flex-column align-items-start">
                                <h1 className="mb-4">Verification</h1>
                                <span className="text-muted">We sent a verification code to your email or phone. Please enter the code below to verify your account.</span>
                                <div className="card mt-4 w-100">
                                    <div className="card-body p-4">
                                        <form>
                                            <div className="d-flex justify-content-between mb-4">
                                                <input type="text" className="form-control form-control-lg text-center" style={{ width: "20%" }} maxLength="1" />
                                                <input type="text" className="form-control form-control-lg text-center" style={{ width: "20%" }} maxLength="1" />
                                                <input type="text" className="form-control form-control-lg text-center" style={{ width: "20%" }} maxLength="1" />
                                                <input type="text" className="form-control form-control-lg text-center" style={{ width: "20%" }} maxLength="1" />
                                            </div>
                                            <button type="submit" className="btn btn-primary text-uppercase py-2 fs-5 w-100">Verify My Account</button>
                                        </form>
                                    </div>
                                </div>
                                <Link to={process.env.PUBLIC_URL + "#!"} className="mt-3 text-primary text-decoration-underline">Resend a new code?</Link>
                                <Link to={process.env.PUBLIC_URL + "/login"} className="mt-2 text-primary text-decoration-underline">Back to Login</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Verification;
