import React from "react";
import {Link} from "react-router-dom";

function Register() {
    return (
        <>
            <div className="body d-flex p-0 p-xl-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="d-flex flex-column align-items-start">
                                <h1 className="mb-4">Get Started with SCADASwarm!</h1>
                                <h5>Create Your Account & Verify Your Email</h5>
                                <span className="text-muted">Sign up today and verify your email to unlock full access to the SCADASwarm ecosystem. Start harnessing the power of cloud-hosted SCADA to optimize and transform your industrial operations. We're excited to build the future of industrial automation with you!</span>
                                <span className="text-decoration-underline mt-4">Welcome to the community where innovation meets efficiency!</span>
                                <div className="card mt-4 w-100">
                                    <div className="card-body p-4">
                                        <form>
                                            <div className="mb-3">
                                                <label className="form-label fs-6">Email address</label>
                                                <input type="email" className="form-control"/>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label fs-6">Password</label>
                                                <input type="password" className="form-control"/>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label fs-6">Username (Optional)</label>
                                                <input type="username" className="form-control"/>
                                            </div>
                                            <button type="submit"
                                                    className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">Register
                                            </button>
                                        </form>
                                        <Link to={process.env.PUBLIC_URL + "/login"} className="d-block mt-3">
                                            Already registered? <span
                                            className="text-secondary text-decoration-underline">Login</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;
