import React from "react";
import { Link } from "react-router-dom";

function ForgotPassword() {
    return (
        <>
            <div className="body d-flex p-0 p-xl-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="d-flex flex-column align-items-start">
                                <h1 className="mb-4">Reset Your Password</h1>
                                <span className="text-muted mb-4">Enter your email address below, and we'll send you a link to reset your password.</span>
                                <div className="card w-100">
                                    <div className="card-body p-4">
                                        <form>
                                            <div className="mb-3">
                                                <label className="form-label fs-6">Email address</label>
                                                <input type="email" className="form-control" />
                                            </div>
                                            <Link to={process.env.PUBLIC_URL + "/verification"} className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">Send Reset Link</Link>
                                        </form>
                                    </div>
                                </div>
                                <Link to={process.env.PUBLIC_URL + "/login"} className="mt-3 text-primary text-decoration-underline">Back to Login</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ForgotPassword;
