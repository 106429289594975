import React from "react";
import { Link } from "react-router-dom";

function Login() {
    return (
        <>
            <div className="body d-flex p-0 p-xl-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="d-flex flex-column align-items-start">
                                <h1 className="mb-4">Welcome Back to SCADASwarm!</h1>
                                <h5>Sign In to Continue</h5>
                                <span className="text-muted">Access your cloud-based SCADA dashboard to manage and optimize your operations. Let's drive innovation together.</span>
                                <div className="card mt-4 w-100">
                                    <div className="card-body p-4">
                                        <form>
                                            <div className="mb-3">
                                                <label className="form-label fs-6">Email address</label>
                                                <input type="email" className="form-control"/>
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label fs-6">Password</label>
                                                <input type="password" className="form-control"/>
                                            </div>
                                            <button type="submit" className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">Log In</button>
                                        </form>
                                        <Link to={process.env.PUBLIC_URL + "/forgot-password"} className="d-block mt-3">Forgot password?</Link>
                                        <Link to={process.env.PUBLIC_URL + "/register"} className="d-block mt-3">Need an account? Register Now!</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
