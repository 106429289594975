import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignInAlt, faHomeAlt } from '@fortawesome/free-solid-svg-icons';
import SCADASwarmLogoBlack from "../../assets/images/base_textlogo_dark.svg";
import SCADASwarmLogoWhite from "../../assets/images/base_textlogo_light.svg";
import SCADASwarmSmallLogoBlack from "../../assets/images/logo_small_black.svg";
import SCADASwarmSmallLogoWhite from "../../assets/images/logo_small_white.svg";

// Create a map of icons
const icons = {
    "faUser": faUser,
    "faSignInAlt": faSignInAlt,
    "faHomeAlt": faHomeAlt
};

function Sidebar(props) {
    const { activekey, menuData, GotoChangeMenu, initIsSidebarMini } = props;

    const [isSidebarMini, setIsSidebarMini] = useState(initIsSidebarMini);
    const ChangeSideBar = (setVal) => {
        localStorage.setItem("isSideBarMini", !setVal);
        setIsSidebarMini(!setVal)
    }

    const navigate = useNavigate();
    const baseUrl = process.env.PUBLIC_URL;

    const getLogo = () => {
        const mode = document.documentElement.getAttribute("data-theme");
        if (isSidebarMini) {
            return mode === 'light' ? SCADASwarmSmallLogoBlack : SCADASwarmSmallLogoWhite;
        } else {
            return mode === 'light' ? SCADASwarmLogoBlack : SCADASwarmLogoWhite;
        }
    }

    const openChildren = (id) => {
        var otherTabs = document.getElementsByClassName("has-children")
        if (otherTabs) {
            for (var i = 0; i < otherTabs.length; i++) {
                if (otherTabs[i].id !== id) {
                    otherTabs[i].className = otherTabs[i].className.replace(" show", "");
                    if (otherTabs[i].parentElement.children.length > 1) {
                        otherTabs[i].parentElement.children[0].setAttribute("aria-expanded", "false")
                    }
                }
            }
        }
        var menutab = document.getElementById(id)
        if (menutab) {
            if (menutab.classList.contains("show")) {
                menutab.classList.remove("show")
                if (menutab.parentElement.children.length > 1) {
                    menutab.parentElement.children[0].setAttribute("aria-expanded", "false")
                }
            } else {
                menutab.classList.add("show")
                if (menutab.parentElement.children.length > 1) {
                    menutab.parentElement.children[0].setAttribute("aria-expanded", "true")
                }
            }
        }
    }
    const openChildren1 = (id) => {
        var otherTabs = document.getElementsByClassName("has-children")
        if (otherTabs) {
            for (var i = 0; i < otherTabs.length; i++) {
                otherTabs[i].className = otherTabs[i].className.replace(" show", "");
            }
        }
        var menutab = document.getElementById(id)
        if (menutab) {
            menutab.classList.add("show")
            if (menutab.parentElement.children.length > 1) {
                menutab.parentElement.children[0].setAttribute("aria-expanded", "true")
            }
        }
    }

    return (<div id="mainsidemenu" className={`sidebar py-2 py-md-2 me-0 border-end ${isSidebarMini === true ? "sidebar-mini" : ""}`}>
            <div className="d-flex flex-column h-100">
                <a href={process.env.PUBLIC_URL + "/"} className="mb-0 brand-icon">
                <span className="logo-icon">
                    <img className="logo-image" src={getLogo()} alt=""/>
                </span>
                </a>
                <ul className={`menu-list flex-grow-1 ${isSidebarMini ? 'mt-5' : 'mt-4'} px-1`}>
                    {menuData.map((item, i) => (
                        <li key={i}>
                            <Link to={baseUrl + "/" + item.routerLink[0]}
                                  className={`m-link ${("/" + item.routerLink[0] === activekey) ? "active" : ""}`}>
                                <FontAwesomeIcon icon={icons[item.icon]} className="me-2"/>
                                <span>{item.name}</span>
                            </Link>
                        </li>
                    ))}
                </ul>
                <button type="button" className="btn btn-link sidebar-mini-btn text-muted" onClick={() => {
                    ChangeSideBar(isSidebarMini)
                }}>
                    <span className="ms-2"><i className="icofont-bubble-right"></i></span>
                </button>
            </div>
        </div>
    )
}

export default Sidebar;